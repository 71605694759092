import mutations from './mutations';
import Cookies from 'js-cookie';
import axios from '@/config/axios'

const state = () => ({
  // status: '',
  login: (localStorage.getItem('accessToken')) ? true : false,
  // token: localStorage.getItem('accessToken') || '',
  user: (localStorage.getItem('userData')) ? JSON.parse((localStorage.getItem('userData'))) : {},
  loading: false,
  error: null,
});

const actions = {
  async checkAuth({commit}) {
    await axios.get('/sanctum/csrf-cookie')

    return axios.get('/auth/me').then(response => {
        console.log(response.data.user);
        commit('setUser', response.data.user)
        commit('loginSuccess', true)
        // router.push({name:'dashboard'})
      }).catch((err)=>{
        commit('setUser', {})
        commit('loginErr', err);
      });
  },

  // async login({ commit }) {
  //   // commit('loginBegin');
  //
  //   await axios.get('/sanctum/csrf-cookie')
  //
  //   return axios.get('/api/user').then(({data}) => {
  //     commit('setUser', data)
  //     commit('loginSuccess', true)
  //     router.push({name:'dashboard'})
  //   }).catch(({response:{data}})=>{
  //     commit('setUser', {})
  //     commit('logedIn', false)
  //     commit('loginErr', data);
  //   })
  // },
  // async logOut({ commit }) {
  //   commit('logoutBegin');
  //   commit('setUser', {})
  //   commit('logoutSuccess', null)
  // },
};


// const actions = {
//   async login({ commit }) {
//     try {
//       commit('loginBegin');
//       Cookies.set('logedIn', true);
//       return commit('loginSuccess', true);
//     } catch (err) {
//       commit('loginErr', err);
//     }
//   },
//   async logOut({ commit }) {
//     try {
//       commit('logoutBegin');
//       Cookies.remove('logedIn');
//       commit('logoutSuccess', null);
//     } catch (err) {
//       commit('logoutErr', err);
//     }
//   },
// };

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
