import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import axios from './config/axios';
import { ObserveVisibility } from 'vue-observe-visibility';
const VueTelInputOptions = {
  mode: "international",
  // onlyCountries: ['RU']
}
import { Quasar } from "quasar";
import MediumEditor from '@/plugins/vue-medium-editor/Editor.vue';
import 'medium-editor/dist/css/medium-editor.css';
import 'vuejs-medium-editor/src/themes/default.css';
import VueImgFallback from 'vue-3-fallback-img';

app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(VueTelInput, VueTelInputOptions); // Define default global options here (optional)

app.use(VueImgFallback, {
    loading: '@/static/img/fallback.jpg',
    error: '@/static/img/fallback.jpg'
});

app.component('medium-editor', MediumEditor);
app.provide('$axios', axios);
app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
});
app.use(Quasar);

app.mount('#app');
