export default {
  loginBegin(state) {
    state.login = true;
  },
  loginSuccess(state, data) {
    console.log('login succeed!');
    console.log(data);
    state.loading = false;
    state.login = data;
    state.login = true;
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
    state.login = false;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  setUser(state, user) {
    state.user = user;
    console.log('setting user');
    console.log(user);

    localStorage.setItem('userData', JSON.stringify(user))

  }
};
