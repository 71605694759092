const routes = [
  {
    path: '',
    name: 'vibes_dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/vibes/dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'vibes_home',
        component: () => import(/* webpackChunkName: "Home" */ '@/view/vibes/dashboard/Home.vue'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: () => import(/* webpackChunkName: "404" */ '@/view/vibes/404.vue'),
  },
  {
    path: '/marathon',
    name: 'marathon',
    component: () => import('@/view/vibes/support/Marathon.vue')
  },
  {
    path: '/production',
    name: 'production',
    component: () => import('@/view/vibes/support/Production.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/view/vibes/support/Support.vue')
  },
  {
    path: '/new_project',
    name: 'new_project',
    component: () => import('@/view/vibes/new_project/NewProject.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/view/vibes/users/Users.vue')
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/view/vibes/subscription/Subscription.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/view/vibes/settings/GeneralSettings.vue')
  },
  {
    path: '/settings/packages',
    name: 'settings/packages',
    component: () => import('@/view/vibes/settings/Packages.vue')
  },
  {
    path: '/settings/chatbot',
    name: 'settings/chatbot',
    component: () => import('@/view/vibes/settings/Chatbot.vue')
  },
  {
    path: '/settings/channels',
    name: 'settings/channels',
    component: () => import('@/view/vibes/settings/Channels.vue')
  },
  {
    path: '/settings/flows',
    name: 'settings/flows',
    component: () => import('@/view/vibes/settings/Flows.vue')
  },
  {
    path: '/settings/users/manage',
    name: 'settings/users/manage',
    component: () => import('@/view/vibes/settings/users/Manage.vue')
  },
  {
    path: '/settings/payments',
    name: 'settings/payments',
    component: () => import('@/view/vibes/BaseRouterView.vue'),
    children: [
      {
        path: '',
        name: '',
        component: () => import('@/view/vibes/settings/Payments.vue')
      },
      {
        path: 'yoomoney',
        name: 'yoomoney',
        component: () => import('@/view/vibes/settings/payment_service/Yoomoney.vue'),
      },
      {
        path: 'qiwi',
        name: 'qiwi',
        component: () => import('@/view/vibes/settings/payment_service/Qiwi.vue'),
      },
    ],
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/view/vibes/knowledge/KnowledgeBase.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '../view/vibes/chat/ChatApp.vue'),
    children: [
        {
            name: 'all',
            path: 'all',
            component: () => import(/* webpackChunkName: "all" */ '../view/vibes/chat/overview/AllContacts.vue'),
        },
        {
            name: 'allSingle',
            path: 'all/:id',
            components: {
                default: () => import(/* webpackChunkName: "private" */ '../view/vibes/chat/overview/AllContacts.vue'),
                child: () => import(/* webpackChunkName: "privateSingle" */ '../view/vibes/chat/overview/SingleChat.vue'),
            },
        },
    ],
  },
  {
        name: 'kanban',
        path: 'crm',
        component: () => import(/* webpackChunkName: "kanban" */ '../view/vibes/kanban/Index.vue'),
  },

    // {
  //   path: '/pages/knowledge-base/:category',
  //   name: 'page-knowledge-base-category',
  //   component: () => import('@/view/vibes/knowledge/KnowledgeBaseCategory.vue'),
  //   meta: {
  //     layout: 'content',
  //     navActiveLink: 'page-knowledge-base',
  //   },
  // },
  // {
  //   path: '/pages/knowledge-base/:category/:subcategory/:slug',
  //   name: 'page-knowledge-base-question',
  //   component: () => import('@/view/vibes/knowledge/KnowledgeBaseQuestion.vue'),
  //   meta: {
  //     layout: 'content',
  //     navActiveLink: 'page-knowledge-base',
  //   },
  // },

  // {
  //   path: '/settings/payments/yoomoney',
  //   name: 'settings/payments/yoomoney',
  //   component: () => import('@/view/vibes/settings/payment_service/Yoomoney.vue')
  // }




];

export default routes;
