import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';

import vibesRoutes from './VibesRoutes';


const routes = [
  {
    name: 'Admin',
    path: '/old',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/Old/withAdminLayoutOld.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Vibes',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...vibesRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
  {
    name: 'Verification',
    path: '/auth/verify',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [
      {
        path: 'phone',
        name: 'phone',
        component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/VerifyMobile.vue'),
      },
    ],
    meta: { auth: false },
  }
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});


router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')
  const isLoggedIn = userData && localStorage.getItem('accessToken')

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  if(!to.meta.auth) {
    if (!isLoggedIn) return next({ name: 'login' })

    if(store.state.auth.user.is_phone_verified === 0) {
      if(to.path != '/auth/verify/phone')
        return next('/auth/verify/phone');
    }

    store.dispatch('checkAuth')
  }
    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  // }

  // if(isLoggedIn) {
  //   axios.get('/auth/projects').then(response => {
  //     const {projects} = response.data;
  //
  //     localStorage.setItem('userProjects', JSON.stringify(projects));
  //
  //     if(projects.length === 0)
  //       return next({name: 'onboarding-add-project'});
  //   });
  // }

  // Redirect if logged in
  if (to.meta.auth && isLoggedIn) {
    next({to: '/'});
  }

  // store.dispatch('checkAuth')

  return next()
})

// router.beforeEach((to, from, next) => {
//   console.log(to, store);
//
//   const hasAccessToken = localStorage.getItem('accessToken') || false;
//
//   if(!hasAccessToken)
//     return next({name: 'login'});
//
//   const isStoreLogin = store.state.auth.login;
//
//   if(isStoreLogin)
//     return next();
//
//   if(to.meta.auth && !isStoreLogin) {
//     return next();
//   }
//
//   if(to.meta.auth && isStoreLogin) {
//     return next({to: '/'});
//   }
//
//   console.log(store.state.auth);
//
//   console.log('checking auth in store');
//
//   store.dispatch('checkAuth').then(response => {
//     console.log(store.state.auth);
//
//     if(!store.state.auth.login) {
//       console.log('redirecting to login');
//       return next({name: 'login'});
//     }
//
//       return next();
//   });
// });

// router.beforeEach((to, from, next) => {
//   // console.log(to, store);
//   if (to.meta.auth && store.state.auth.login) {
//     next({ to: '/' });
//   } else if (!to.meta.auth && !store.state.auth.login) {
//     next({ name: 'login' });
//   } else {
//     next();
//   }
// });

export default router;
