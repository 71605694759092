// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => {
    Promise.reject(error)
  },
);

axiosIns.interceptors.response.use(
  config => { return config },
  error => {
    console.log(error.response.data);

    if (error.response.status === 401) {
      console.log('Invalidating session');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('userAbility');

      window.location = '/auth/login'
    }

    return Promise.reject(error)
  }
)


export default axiosIns
